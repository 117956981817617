* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --primary-color: #fbf300;
  --secondary-color: #0155a3;
  --primary-text-color: #fff;
}

#BODY {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.678), rgba(0, 0, 0, 0)),
    url("https://w.forfun.com/fetch/3c/3cd2b06c8b9e346a2a0fecfded9e54ce.jpeg");
  background-size: cover;
}

.main-route {
  flex: 1;
}

.footer-new {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  background-color: var(--secondary-color);
  padding: 0.8rem 0rem;
}

.login-btn-new {
  border: none;
  outline: none;
  border-radius: 10rem;
  padding: 0.4rem 1.2rem;
  font-size: 0.78rem;
}

.profile-name {
  color: #ffffffe0;
  font-weight: 600;
  font-size: 0.8rem;
  letter-spacing: 0.6px;
}

.profile-border {
  border-top: 1px dotted rgba(255, 255, 255, 0.233);
}

.profile-amount {
  font-size: 0.7rem;
  letter-spacing: 0.08rem;
}

.profile-wallet {
  font-size: 0.8rem;
  letter-spacing: 0.06rem;
}

.profile-point {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2;
  width: 50%;
  border-radius: 5px;
  background-color: #15054465;
  padding: 0.3rem 0rem;
  cursor: pointer;
  transition-duration: 500ms;
}

.profile-point:hover {
  scale: 1.01;
}

.login-new-divide {
  position: absolute;
  transform: translateY(10%);
  border-top: 1px solid rgba(128, 128, 128, 0.171);
}

.login-or {
  z-index: 0;
  background-color: #fff;
  font-size: 0.9rem;
  width: 4rem;
}

.spinner-border {
  width: 25px;
  height: 25px;
}

.spinner-style {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0px;
  background-color: #110241fb;
}

.commonModal {
  z-index: 2;
  height: 100%;
  background-color: red;
  outline: none;
}

.commonModal-min {
  position: absolute;
  border-radius: 2px;
  top: 50%;
  left: 50%;
  width: 95%;
  max-width: 480px;
  min-height: 20%;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: none;
}

.wallet-new {
  width: 100%;
  max-width: 49%;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem;
  color: inherit;
  text-decoration: none;
}

.social-image {
  width: 80px;
  height: 80px;
  object-fit: fill;
}

input[id="nofocus"]::placeholder {
  font-size: 0.75rem;
}

.overlay-style {
  background-color: red;
}

.bet-action-btn {
  width: 100%;
  max-width: 480px;
  position: fixed;
  left: 50%;
  bottom: 16px;
  padding: 0rem 1rem;
  transform: translateX(-50%);
}

.primary-color {
  background-color: #6b35aa;
}

.text-purple {
  color: #9642f7;
}

.btn-indigo {
  background-color: var(--secondary-color);
  color: var(--primary-text-color);
}

.accordion-button:focus {
  outline: none;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: var(--secondary-color);
}

/* Livechat */
.lcmain {
  position: fixed;
  bottom: 4rem;
  right: 4rem;
  z-index: 10;
}

.lciframe {
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  max-width: 480px;
  right: 0;
  background-color: #16064c;
}

.lciframe.open {
  transform: translate(0%, 0%);
  transition: all 300ms ease-in-out;
}

.lciframe.close {
  opacity: 0;
  transform: translate(100%, 0%);
  transition: all 300ms ease-in-out;
}

.lclose {
  height: 3rem;
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
  background-color: var(--secondary-color);
  justify-content: space-between;
}

.livechatiframe {
  width: 100%;
  height: 100%;
}

#lcimage {
  width: 3.7rem;
}

.hotgame_div {
  margin: 4rem auto;
}

.category_div {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
}

.category_each {
  width: 100%;
  height: 11rem;
  object-fit: cover;
  border-radius: 10px;
}

.cash_img {
  width: 100%;
  height: 6rem;
  background-color: #fff;
}

.history_fs {
  font-size: 18px;
}

.history_fs_md {
  font-size: 15px;
}

.history_fs_sm {
  font-size: 13px;
}

.hotgame_item {
  min-height: 10rem;
  height: 10rem;
  max-height: 10rem;
  object-fit: cover;
}

@media (max-width: 480px) {
  .lcmain {
    bottom: 6rem;
    right: 0.5rem;
  }
  #lcimage {
    width: 2.7rem;
  }
  .hotgame_div {
    margin: 0;
  }
  .category_each {
    width: 100%;
    height: 8rem;
  }
  .cash_img {
    height: 4rem;
  }
  .history_fs {
    font-size: 13px;
  }
  .history_fs_md {
    font-size: 12px;
  }
  .history_fs_sm {
    font-size: 10px;
  }
  .hotgame_item {
    min-height: 5rem;
    height: 5rem;
    max-height: 5rem;
    object-fit: cover;
  }
}

.lineclamp {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
